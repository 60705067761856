<template>
  <v-card>
    <v-card-title class="headline">
      {{ $vuetify.lang.t('$vuetify.sosAlarm.progress') }}
    </v-card-title>
    <v-card-text
    >
      <v-form
        ref="form"
      >
        <v-row>
          <v-col
            cols="12"
          >
            <v-textarea
              v-model="form.progressContent"
              :label="$vuetify.lang.t('$vuetify.common.description')"
              :rules="rules.progressContent"
            />
          </v-col>

          <v-col
            cols="12"
          >
            <v-chip-group
              v-model="form.progressType"
              column
            >
              <v-chip
                v-for="(item, index) in progressTypeItems"
                :key="`progress-type-${ index }`"
                :value="item"
                @click="handleChipClick(item)"
              >
                {{$vuetify.lang.t(`$vuetify.progressType.${ snakeToCamel(item.toLowerCase()) }`)}}
              </v-chip>
            </v-chip-group>
          </v-col>

        </v-row>
      </v-form>

    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="info darken-1"
        @click="handleCancel"
      >
        {{ $vuetify.lang.t('$vuetify.common.cancel') }}
      </v-btn>
      <v-btn
        color="primary"
        @click="handleSubmit"
      >
        {{ $vuetify.lang.t('$vuetify.common.submit') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>
  import { snakeToCamel } from '@/utils';

  export default {
    props: {
      editItem: {
        type: Object,
        default: () => null
      }
    },

    data() {
      return {
        form: {
          progressType: null,
          progressContent: null
        },
        progressTypeItems: [
          'USER_MISREPORT',
          'TELEPHONE_GUIDANCE',
          'VISIT_RESCUE'
        ],
        rules: {
          progressContent: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.sosAlarm.progressContent')])
          ]
        }
      }
    },

    mounted () {
      this.initialize()
    },

    methods: {

      initialize () {
        if (this.editStatus) {
          for (let p in this.form) {
            if (p in this.editItem) {
              this.form[p] = this.editItem[p]
            } else {
              this.form[p] = null
            }
          }
        } else {
          this.resetFrom()
        }
      },

      resetFrom () {
        for (let key in this.form) {
          this.form[key] = null
        }
        this.$refs.form.resetValidation()

      },

      handleCancel() {
        this.$emit('cancel', true)
      },

      handleSubmit() {
        if (this.$refs.form.validate()) {
          const data = Object.assign({}, this.form)
          this.$emit('submit', data)
        }
      },

      snakeToCamel,

      handleChipClick (item) {
        let suffix = this.$vuetify.lang.t(`$vuetify.progressType.${ this.snakeToCamel(item.toLowerCase()) }`)
        this.form.progressContent = this.form.progressContent ? this.form.progressContent + suffix : suffix
      }
    }
  }
</script>
